import config from 'config';
import { List, PaginatedResult, PaginationOptions, SortOptions } from 'types/api';
import {
  CompanyListItem,
  CompanyProfile,
  CompanyStats,
  ExecutiveSummaryDTO,
  ICompany,
  UserStats,
  VideoListDTO,
} from 'types/company/company';
import { TinyOrganizationDTO } from 'types/organization';
import {
  BackofficeEvent,
  BackofficeEventListItem,
  BackofficeMatchmakingCompaniesSummaryDTO,
  BackofficeMatchmakingCompanyMatchDTO,
  BackofficeMatchMakingCompanyMatchesDTO,
  BackofficeMatchmakingLabeledDTO,
  BackofficeMatchmakingStagedDTO,
  BackofficeMatchmakingUserMatchDTO,
  BackofficeMatchmakingUsersSummaryDTO,
  BackofficeOrganizationDTO,
  BackofficePatchCommunity,
  BackofficeUpdateCompanyDTO,
  BackofficeUserDetailsDTO,
  BackofficeUserDTO,
} from 'types/backoffice';
import { UserProfile, UserProfileDeleteRequest } from 'types/user';
import { CompactEventDTO, EventDTO, NewEventDTO, UpdateEventDTO } from 'ui/domain/Event/types';
import ApiBase from '../ApiBase';
import { withPagination } from 'apis/utils';
import { CompanyId } from 'urls';
import { ImpactGoal, Industry } from 'types';
import { MarketPotential } from 'apis/CompanyAPI/companies/useMarketPotential';
import { HighlightedSectionList } from 'apis/CompanyAPI/companies/companiesApi';
import { SortDirection } from 'ui/elements/tables/Table/useTableWithSort';
import { TinyCommunity } from 'types/company/community';

const baseUrl = config.COMPANIES_API_URL;

const api = ApiBase();

export const backofficeApiUrls = {
  companies: {
    list: (
      paginationOptions: PaginationOptions,
      options: { showApproved?: boolean; showTestCompanies?: boolean; hasCompletedBasicProfile?: boolean },
    ) => {
      return withPagination(`${baseUrl}/backoffice/companies`, paginationOptions, options);
    },
    get: (companyId: CompanyId) => `${baseUrl}/backoffice/companies/${companyId}`,
  },
  communities: {
    list: `${baseUrl}/backoffice/communities`,
    applicationQuestions: {
      list: (communityId: number) => `${baseUrl}/backoffice/communities/${communityId}/application-questions`,
      post: (communityId: number) => `${baseUrl}/backoffice/communities/${communityId}/application-questions`,
      patch: (communityId: number, questionId: UUID) =>
        `${baseUrl}/backoffice/communities/${communityId}/application-questions/${questionId}`,
      delete: (communityId: number, questionId: UUID) =>
        `${baseUrl}/backoffice/communities/${communityId}/application-questions/${questionId}`,
    },
  },
  organizations: {
    patch: (organizationId: UUID) => `${baseUrl}/backoffice/organizations/${organizationId}`,
    patchSettings: (organizationId: UUID) => `${baseUrl}/backoffice/organizations/${organizationId}/settings`,
  },
};

export default {
  stats: {
    userStats: () => api.get<UserStats>(`${baseUrl}/stats/user`),
    companyStats: () => api.get<CompanyStats>(`${baseUrl}/stats/company`),
  },
  companies: {
    patch: (companyId: CompanyId, dto: BackofficeUpdateCompanyDTO) =>
      api.patch<ICompany>(`${baseUrl}/backoffice/companies/${companyId}`, dto),
    delete: (companyId: CompanyId) => api.deleteRequest(`${baseUrl}/backoffice/companies/${companyId}`),
    getProfile: (id: CompanyId) => api.get<CompanyProfile>(`${baseUrl}/backoffice/companies/${id}/profile`),
    joinCommunity: (companyId: number, inviteCode: string) =>
      api.post<void>(`${baseUrl}/backoffice/companies/${companyId}/join-community/${inviteCode}`, {}),
    getProfileSections: (id: CompanyId) =>
      api.get<{
        videos: VideoListDTO;
        marketPotential?: MarketPotential;
        executiveSummary: ExecutiveSummaryDTO;
        impactgoals: ImpactGoal[];
        team: HighlightedSectionList;
      }>(`${baseUrl}/backoffice/companies/${id}/profile-sections`),
  },
  communities: {
    patch: (communityId: number, dto: BackofficePatchCommunity) =>
      api.patch(`${baseUrl}/backoffice/communities/${communityId}`, dto),
    patchLinkedCommunities: (communityId: number, dto: { communityIds: number[] }) =>
      api.patch(`${baseUrl}/backoffice/communities/${communityId}/linked-communities`, dto),
    getLinkedCommunities: (communityId: number) =>
      api.get<List<TinyCommunity>>(`${baseUrl}/backoffice/communities/${communityId}/linked-communities`),
    listTeam: (communitySlug: string, params?: PaginationOptions) =>
      api.get<PaginatedResult<UserProfile>>(`${baseUrl}/backoffice/communities/${communitySlug}/team`, {
        requestParams: params,
      }),
  },
  users: {
    queryUsers: (
      filter: { name?: string; email?: string },
      paginationOptions: PaginationOptions,
      sortOrder?: SortOptions,
    ) =>
      api.get<PaginatedResult<BackofficeUserDTO>>(`${baseUrl}/backoffice/users`, {
        requestParams: { ...paginationOptions, ...filter, ...sortOrder },
      }),
    get: (id: number) => api.get<BackofficeUserDetailsDTO>(`${baseUrl}/backoffice/users/${id}`),
    delete: (email: string) => api.deleteRequest(`${baseUrl}/backoffice/users`, { email }),
    deletionRequests: {
      post: (cwUserid: string) => api.post(`${baseUrl}/backoffice/users/delete-requests/${cwUserid}`, {}),
      get: () => api.get<{ values: UserProfileDeleteRequest[] }>(`${baseUrl}/backoffice/users/delete-requests`),
    },
  },
  notifyEvents: {
    list: (
      eventType: string[],
      hasError: string | undefined,
      hasWorkFailures: string | undefined,
      paginationOptions: PaginationOptions,
    ) => {
      const params = new URLSearchParams();
      eventType.map(type => params.append('eventType', type));
      if (hasError) {
        params.append('hasError', '1');
      }
      if (hasWorkFailures) {
        params.append('hasWorkFailures', '1');
      }

      return api.get<PaginatedResult<BackofficeEventListItem>>(
        `${config.NOTIFY_API_URL}/backoffice/mail-events?${params.toString()}`,
        {
          requestParams: { ...eventType, ...paginationOptions },
        },
      );
    },
    get: (id: number, workStatusFilter: string[], eventTypeFilter: string[]) => {
      const params = new URLSearchParams();
      workStatusFilter.map(value => params.append('workStatus', value));
      eventTypeFilter.map(value => params.append('eventType', value));

      return api.get<BackofficeEvent>(
        `${config.NOTIFY_API_URL}/backoffice/mail-events/event/${id}?${params.toString()}`,
      );
    },
    retry: (id: number) => api.post(`${config.NOTIFY_API_URL}/backoffice/mail-events/event/${id}/retry`, {}),
  },
  events: {
    list: (paginationOptions?: PaginationOptions, filter?: { title?: string }) =>
      api.get<PaginatedResult<CompactEventDTO>>(
        withPagination(`${baseUrl}/backoffice/events`, paginationOptions, filter),
      ),
    get: (id: string) => api.get<EventDTO>(`${baseUrl}/backoffice/events/${id}`),
    create: (payload: NewEventDTO) => api.post<EventDTO>(`${baseUrl}/backoffice/events`, payload),
    update: (eventId: UUID, payload: UpdateEventDTO) =>
      api.put<EventDTO>(`${baseUrl}/backoffice/events/${eventId}`, payload),
    delete: (id: UUID) => api.deleteRequest<EventDTO>(`${baseUrl}/backoffice/events/${id}`),
  },
  organizations: {
    list: (filters: { name?: string }) =>
      api.get<TinyOrganizationDTO[]>(`${baseUrl}/backoffice/organizations`, { requestParams: filters }),
    get: (organizationId: string) =>
      api.get<BackofficeOrganizationDTO>(`${baseUrl}/backoffice/organizations/${organizationId}`),
    queryCommunities: (query: string) =>
      api.get<CompanyListItem[]>(`${baseUrl}/backoffice/organizations/communities?query=${query}`),
    listOrganizationCommunities: (organizationId: string) =>
      api.get<TinyCommunity[]>(`${baseUrl}/backoffice/organizations/${organizationId}/communities`),
    create: (payload: any) => api.post<{ id: UUID }>(`${baseUrl}/backoffice/organizations`, payload),
    update: (organizationId: string, payload: any) =>
      api.put(`${baseUrl}/backoffice/organizations/${organizationId}`, payload),
  },
  matchmaking: {
    generateMatches: () => api.post<number>(`${baseUrl}/backoffice/matchmaking/generate`, {}),
    users: () => api.get<BackofficeMatchmakingUsersSummaryDTO>(`${baseUrl}/backoffice/matchmaking/users`),
    companies: () => api.get<BackofficeMatchmakingCompaniesSummaryDTO>(`${baseUrl}/backoffice/matchmaking/companies`),
    getMatchesForUser: (cwUserId: string) =>
      api.get<BackofficeMatchmakingUserMatchDTO[]>(`${baseUrl}/backoffice/matchmaking/users/${cwUserId}`),
    getMatchesForCompany: (companyId: string) =>
      api.get<BackofficeMatchMakingCompanyMatchesDTO>(`${baseUrl}/backoffice/matchmaking/companies/${companyId}`),
    updateUserMatch: (cwUserId: string, companyId: number, staged: boolean) =>
      api.put<BackofficeMatchmakingUserMatchDTO>(
        `${baseUrl}/backoffice/matchmaking/users/${cwUserId}/company/${companyId}`,
        {
          staged,
        },
      ),
    updateCompanyMatch: (companyId: number, cwUserId: string, staged: boolean) =>
      api.put<BackofficeMatchmakingCompanyMatchDTO>(
        `${baseUrl}/backoffice/matchmaking/companies/${companyId}/users/${cwUserId}`,
        {
          staged,
        },
      ),
    staged: () => api.get<BackofficeMatchmakingStagedDTO[]>(`${baseUrl}/backoffice/matchmaking/staged`),
    labeled: () => api.get<BackofficeMatchmakingLabeledDTO[]>(`${baseUrl}/backoffice/matchmaking/labeled`),
    notify: (label: string) => api.post(`${baseUrl}/backoffice/matchmaking/notify`, { label }),
  },
  industries: {
    list: () =>
      api.get<List<{ industry: Industry; numCompanies: number; numInvestors: number }>>(
        `${baseUrl}/backoffice/industries`,
      ),
    patch: (industryId: number, tags: string[]) =>
      api.patch(`${baseUrl}/backoffice/industries/${industryId}`, { tags }),
  },
  registrationTracking: {
    list: (
      paginationOptions?: PaginationOptions,
      filter?: {
        days?: number;
        utmSource?: string;
        utmMedium?: string;
        utmCampaign?: string;
        utmTerm?: string;
        utmContent?: string;
        sortBy?: string;
        sortOrder?: SortDirection;
      },
    ) => withPagination(`${baseUrl}/backoffice/registration-tracking`, paginationOptions, filter),
    users: (queryParams: string, dayInterval?: number) =>
      `${baseUrl}/backoffice/registration-tracking/users${queryParams}&days=${dayInterval}`,
  },
};
