import { useMediaQuery } from '@mui/material';
import useContainerWidth from 'hooks/useContainerWidth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Document, Thumbnail } from 'react-pdf';
import Carousel from 'ui/elements/Carousel';
import { bluePlanetTheme } from 'ui/theme';
import styles from './styles.scss';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { useDownloadPitchDocument } from 'domain/documents/useDownloadDocument';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import { DocumentDTO } from 'types/documents';
import DownloadIcon from 'ui/elements/icons/DownloadIcon';
import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import useFollowCompany from 'domain/companies/Follow/useFollowCompany';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import StatsIcon from 'ui/elements/icons/StatsIcon';
import LinkButton from 'ui/elements/buttons/LinkButton';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import PitchDeckInsights from 'pages/Company/Insights/PitchDeckInsights';
import { getDefaultDateRange } from 'ui/elements/DateRangePresetsPicker';
import { useSwipeable } from 'react-swipeable';
import { defaultBleedAmounts } from 'ui/views/layouts/BaseLayout';
import styled from '@emotion/styled';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import LazyLoadSlide from './LazyLoadSlide';
import { DotButton, useDotButton } from './PitchDeckPreviewDotButton';

const ThumbnailWrapper = styled.div(
  ({ isActive }: { isActive: boolean }) => `
  height: 100%;

  & canvas {
    width: 100% !important;
  }

  & > a {
    display: block;
  }

  ${isActive ? `border: 2px solid ${bluePlanetTheme.bluePlanetPalette.indigo.light};` : ''}
  & * {
    height: 100% !important;
  }
`,
);

interface Props {
  document: DocumentDTO;
  company: ICompany;
  pitchDeckPath: string;
  pageNumber?: number;
  numPages?: number;
  setNumPages: (numPages: number) => void;
  onThumbnailClick: (page?: number) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onPageChange: (num: number) => void;
  viewAs: CompanyViewAs;
  pitchDeckTitle?: string;
}

export default function PitchDeckPDFPreview({
  document,
  company,
  pitchDeckPath,
  pageNumber,
  numPages,
  setNumPages,
  onThumbnailClick,
  onNextPage,
  onPreviousPage,
  onPageChange,
  viewAs,
  pitchDeckTitle,
}: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const [downloadDocument, isDownloading] = useDownloadPitchDocument(company.id);

  const { onClick: onDownloadClick, WarningDialog } = useDownloadWarning(document.name, () =>
    downloadDocument(document.id),
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const leftNavigationRef = React.useRef<HTMLDivElement>(null);
  const rightNavigationRef = React.useRef<HTMLDivElement>(null);
  const [isThumbnailHighlighted, setIsThumbnailHighlighted] = useState(false);
  const [isLeftNavigationHighlighted, setIsLeftNavigationHighlighted] = useState(false);
  const [isRightNavigationHighlighted, setIsRightNavigationHighlighted] = useState(false);
  const [slidesInView, setSlidesInView] = useState<number[]>([]);
  const [emblaRef, emblaApi] = useEmblaCarousel({});
  const mainThumbnailWidth = useContainerWidth(containerRef, [window.innerWidth, pitchDeckPath, numPages]);

  const [isPitchDeckViewsDialogOpen, setPitchDeckViewsDialogOpen] = useState(false);

  const { isFollowingCompany, hasOtherRoleThanFollower } = useFollowCompany(company);

  const isTouchScreen = navigator.maxTouchPoints > 0;

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const updateSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
    setSlidesInView(slidesInView => {
      if (slidesInView.length === emblaApi.slideNodes().length) {
        emblaApi.off('slidesInView', updateSlidesInView);
      }
      const inView = emblaApi.slidesInView().filter(index => !slidesInView.includes(index));
      return slidesInView.concat(inView);
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    updateSlidesInView(emblaApi);
    emblaApi.on('reInit', updateSlidesInView);
    emblaApi.on('slidesInView', updateSlidesInView);
  }, [emblaApi, updateSlidesInView]);

  const handleLeftNavigationPointerMove = () => {
    if (!isLeftNavigationHighlighted) {
      setIsLeftNavigationHighlighted(true);
      setIsRightNavigationHighlighted(false);
    }
  };

  const handleRightNavigationPointerMove = () => {
    if (!isRightNavigationHighlighted) {
      setIsRightNavigationHighlighted(true);
      setIsLeftNavigationHighlighted(false);
    }
  };

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    preventScrollOnSwipe: true,
    onSwipedRight: onPreviousPage,
    onSwipedLeft: onNextPage,
  });

  const SLIDES = useMemo(() => Array.from(Array(numPages).keys()), [numPages]);

  return (
    <>
      <Document
        file={pitchDeckPath}
        onLoadSuccess={onDocumentLoadSuccess}
        externalLinkTarget="_blank"
        className="u-content-spacing-bottom"
        loading={<CircularProgress />}
        noData=""
      >
        <div className="u-flex u-flex--gap-half u-flex-align-center u-flex-space-between u-content-spacing-bottom">
          {pitchDeckTitle && <span className="text-large text-weight-medium">{pitchDeckTitle}</span>}
          <div className="u-margin-left-auto u-flex u-flex--gap-2">
            {viewAs === 'Admin' && (
              <LinkButton
                className="text-link text-medium u-flex u-flex--gap-half"
                onClick={() => setPitchDeckViewsDialogOpen(true)}
                data-intercom-target="view-pitch-deck-insights"
              >
                View insights <StatsIcon fontSize="medium" />
              </LinkButton>
            )}
            {viewAs !== 'Visitor' && (isFollowingCompany || hasOtherRoleThanFollower) ? (
              <LinkButton
                className="text-link text-medium u-flex u-flex--gap-half"
                onClick={onDownloadClick ?? (() => null)}
              >
                Download pdf
                {isDownloading ? (
                  <CircularProgress size={24} />
                ) : (
                  <span className="text-link-small u-flex-center u-flex--align-items-center">
                    <DownloadIcon fontSize="medium" />
                  </span>
                )}
              </LinkButton>
            ) : (
              <span className="text-grey text-weight-medium text-medium u-flex u-flex--gap-half u-margin-left-auto">
                Follow to download
                <span className="u-flex-center u-flex--align-items-center">
                  <DownloadIcon fontSize="medium" />
                </span>
              </span>
            )}
          </div>
        </div>
        <div {...swipeHandlers}>
          <div
            key={`page_${pageNumber}`}
            ref={containerRef}
            className={styles.preview}
            onPointerEnter={() => !isTouchScreen && setIsThumbnailHighlighted(true)}
            onPointerLeave={() => !isTouchScreen && setIsThumbnailHighlighted(false)}
          >
            {!isMobile && (
              <Thumbnail
                pageNumber={pageNumber ?? 1}
                width={mainThumbnailWidth}
                onItemClick={() => onThumbnailClick()}
                className={styles.mainPreview}
              >
                <div
                  className={cx(styles.navigateLeft, {
                    [styles.highlighted]: isLeftNavigationHighlighted,
                    [styles.hidden]: (pageNumber && pageNumber < 2) || !isThumbnailHighlighted,
                  })}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onPreviousPage();
                  }}
                  ref={leftNavigationRef}
                  onPointerEnter={() => setIsLeftNavigationHighlighted(true)}
                  onPointerLeave={() => setIsLeftNavigationHighlighted(false)}
                  onPointerMove={handleLeftNavigationPointerMove}
                >
                  <div className={styles.navigateArrow}>
                    <ChevronLeftIcon />
                  </div>
                </div>
                <div
                  className={cx(styles.navigateRight, {
                    [styles.highlighted]: isRightNavigationHighlighted,
                    [styles.hidden]: (pageNumber && numPages && pageNumber >= numPages) || !isThumbnailHighlighted,
                  })}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onNextPage();
                  }}
                  ref={rightNavigationRef}
                  onPointerEnter={() => setIsRightNavigationHighlighted(true)}
                  onPointerLeave={() => setIsRightNavigationHighlighted(false)}
                  onPointerMove={handleRightNavigationPointerMove}
                >
                  <div className={styles.navigateArrow}>
                    <ChevronRightIcon />
                  </div>
                </div>
              </Thumbnail>
            )}
          </div>
        </div>
        {!isMobile ? (
          <Carousel
            bleed={{ ...defaultBleedAmounts, lg: undefined }}
            slidesWidth={{ sm: '45%', md: '45%', lg: '22%' }}
            highlightedSlideIndex={pageNumber ? pageNumber - 1 : undefined}
            options={{ showNavigation: 'on-hover', slidesToScroll: 1 }}
          >
            {Array.from(new Array(numPages).keys(), index => (
              <ThumbnailWrapper isActive={index + 1 === pageNumber} key={`page_${index + 1}`}>
                <Thumbnail
                  pageNumber={index + 1}
                  onItemClick={({ pageNumber }) => (isMobile ? onThumbnailClick(pageNumber) : onPageChange(pageNumber))}
                />
              </ThumbnailWrapper>
            ))}
          </Carousel>
        ) : (
          <div>
            <div className={styles.emblaViewport} ref={emblaRef}>
              <div className={styles.emblaContainer}>
                {SLIDES.map(index => (
                  <LazyLoadSlide
                    key={index}
                    index={index}
                    inView={slidesInView.indexOf(index) > -1}
                    onThumbnailClick={onThumbnailClick}
                  />
                ))}
              </div>
            </div>
            <div className={styles.emblaDots}>
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={cx(styles.emblaDot, {
                    [styles.emblaDotSelected]: index === selectedIndex,
                  })}
                />
              ))}
            </div>
          </div>
        )}
      </Document>
      <CenteredModalDialog
        open={isPitchDeckViewsDialogOpen}
        onClose={() => setPitchDeckViewsDialogOpen(false)}
        overflowY="scroll" // always show scrollbar to prevent window from jumping
        width="wide"
      >
        <PitchDeckInsights company={company} dateRange={getDefaultDateRange()} shouldDisplayInsightsLink />
      </CenteredModalDialog>
      {WarningDialog}
    </>
  );
}
