import React from 'react';
import { allInterests, DetailedUserProfile } from 'types/user';
import BusinessDomainChip from 'ui/domain/Chips/BusinessDomainChip';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import SectionHeading from 'ui/elements/SectionHeading';
import styles from './styles.scss';
import Card from 'ui/views/cards/Card';
import cx from 'classnames';

import LinkedIn from 'ui/domain/Contact/LinkedIn';
import Phone from 'ui/domain/Contact/Phone';
import ConversationLink from '../Common/ConversationLink';
import Insights from './Insights';
import UserFollowRequests from './UserFollowRequests';
import UserProfileDropdown from './UserProfileDropdown';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { useMediaQuery } from '@mui/material';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import Chip from 'ui/elements/Chip';
import InvolvementList from './InvolvementList';

interface Props {
  className?: string;
  user: DetailedUserProfile;
}

const Label = styled.label`
  display: block;
  margin-bottom: ${halfSpacing};
`;

const ResponsiveContactWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${halfSpacing};

  ${bluePlanetTheme.breakpoints.up('sm')} {
    flex-direction: row;
    gap: 0;
  }
`;

const SkillsAndInterests = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${contentSpacing};
`;

export default function KeyUserInfo(props: Props) {
  const { user } = props;
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const hasExperience =
    (user.educationLevel && user.educationLevel !== 'none') ||
    (user.yearsOfProfessionalExperience && user.yearsOfProfessionalExperience !== 'none');

  const hasInvolvement = user.involvement && Object.values(user.involvement).includes(true);

  const hasBioSection =
    user.description ||
    user.industries.length > 0 ||
    user.businessDomains.length > 0 ||
    user.interests.length > 0 ||
    hasExperience ||
    hasInvolvement;

  const chipsLimit = isMobile ? 4 : Infinity;

  return (
    <div className={props.className}>
      <Card padding="half" className="u-content-spacing-bottom u-relative">
        <div style={{ height: '5rem' }} />
        <div style={{ position: 'absolute', top: halfSpacing, right: halfSpacing }}>
          <UserProfileDropdown cwUserId={user.cwUserId} />
        </div>
        <div className="u-flex u-flex-center u-flex-align-center u-flex--column u-content-spacing-bottom">
          <h3 className="text-h3 u-content-spacing-top">{user.name.trim() ?? ''}</h3>
          <p className={cx(styles.currentWork, 'u-half-spacing-bottom')}>
            {[user.position, user.employer].filter(Boolean).join(' at ')}
          </p>
          <p className={`u-quarter-spacing-bottom ${styles.location}`}>
            {[user.city, user.country].filter(Boolean).join(', ')}
          </p>
          <ResponsiveContactWrapper>
            <ConversationLink className="u-half-spacing-right" hasIcon={true} user={user}></ConversationLink>
            {user.linkedInUrl && user.linkedInUrl.trim() !== '' && (
              <LinkedIn className="u-half-spacing-x" linkedInUrl={user.linkedInUrl} />
            )}
            {user.phone && user.phone.trim() !== '' && <Phone className="u-half-spacing-left" phone={user.phone} />}
          </ResponsiveContactWrapper>
        </div>
      </Card>
      <Insights user={props.user} />
      <UserFollowRequests user={props.user} />
      {hasBioSection && (
        <Card>
          {user.description && (
            <div className="u-section-spacing-bottom">
              <SectionHeading heading="Bio" />
              <p className="text-body">{user.description}</p>
            </div>
          )}
          {(user.industries.length > 0 || user.businessDomains.length > 0 || user.interests.length > 0) && (
            <div className="u-section-spacing-bottom">
              <SectionHeading heading="Skills & interests" />
              <SkillsAndInterests>
                {user.industries.length > 0 && (
                  <div>
                    <h6 className="text-metadata u-half-spacing-bottom">Industries</h6>
                    <ChipList>
                      <TruncatedList
                        values={user.industries}
                        limit={chipsLimit}
                        renderItem={label => <IndustryChip label={label.name} />}
                        renderExpandButton={(label, onClick) => <IndustryChip label={label.name} onClick={onClick} />}
                      />
                    </ChipList>
                  </div>
                )}
                {user.businessDomains.length > 0 && (
                  <div>
                    <h6 className="text-metadata u-half-spacing-bottom">Business Domains</h6>
                    <ChipList>
                      <TruncatedList
                        values={user.businessDomains}
                        limit={chipsLimit}
                        renderItem={label => <BusinessDomainChip label={label.name} />}
                        renderExpandButton={(label, onClick) => (
                          <BusinessDomainChip label={label.name} onClick={onClick} />
                        )}
                      />
                    </ChipList>
                  </div>
                )}
                {user.interests.length > 0 && (
                  <div>
                    <h6 className="text-metadata u-half-spacing-bottom">Interests</h6>
                    <ChipList>
                      <TruncatedList
                        values={user.interests.map(x => ({ name: x, label: allInterests[x] }))}
                        limit={chipsLimit}
                        renderItem={label => <Chip color="blue-light" label={label.name} />}
                        renderExpandButton={(label, onClick) => (
                          <Chip color="blue-light" label={label.name} onClick={onClick} />
                        )}
                      />
                    </ChipList>
                  </div>
                )}
              </SkillsAndInterests>
            </div>
          )}
          {hasExperience && (
            <div>
              <SectionHeading heading="Experience" />
              <div className="u-flex">
                {user.yearsOfProfessionalExperience && user.yearsOfProfessionalExperience !== 'none' && (
                  <div className="u-section-spacing-right">
                    <Label className="text-metadata">Years of professional experience</Label>
                    <span className="text-h4">{user.yearsOfProfessionalExperience}</span>
                  </div>
                )}
                {user.educationLevel && user.educationLevel !== 'none' && (
                  <div>
                    <Label className="text-metadata">Highest level of education</Label>
                    <span className="text-h4">{user.educationLevel}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {hasInvolvement && (
            <div className="u-content-spacing-top">
              <SectionHeading heading="Involvement" />
              <InvolvementList involvement={user.involvement!} />
            </div>
          )}
        </Card>
      )}
    </div>
  );
}
