import useSWR, { invalidate } from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { userAPIUrls } from './usersApi';
import { fetched, getOrUndefined, IResource } from 'util/resource';
import { patch } from 'apis/ApiBase';
import { logError } from 'util/logging';
import useNotify from 'hooks/useNotify';

// We use keys instead of arrays to support partial updates
export interface UiState {
  communities?: {
    [communityId: number]: {
      manageCompaniesTableColumns?: string[];
      selectedBoardGroup?: UUID;
    };
  };
  closedMessages?: {
    [key: string]: boolean;
  };
  dismissedMatches?: {
    [key: number]: boolean;
  };
}

export function asObjectWithKeys(values: number[]) {
  return values.reduce(
    (acc, curr) => {
      acc[curr] = true;
      return acc;
    },
    {} as Record<number, boolean>,
  );
}

function persist(value: UiState) {
  return patch(userAPIUrls.uiState.update(), { value: JSON.stringify(value) });
}

export function useUiState() {
  const notify = useNotify();

  const swrResult = useSWR<UiState>(userAPIUrls.uiState.get());
  const resource = useSWRResource(swrResult);
  const uiState: IResource<UiState> =
    // We fall back to {} in case of failure, in order to degrade gracefully
    resource.resource.state === 'error' ? fetched({}) : resource.resource;

  function saveUiState(
    updatedValues: UiState,
    { successMessage, errorMessage }: { successMessage?: string; errorMessage?: string } = {},
  ) {
    const existingState = getOrUndefined(uiState);
    invalidate(userAPIUrls.uiState.get(), { ...existingState, ...updatedValues });

    // We update the state with the result from the backend in case the user has made changes in a different tab
    persist(updatedValues)
      .then(result => {
        invalidate(userAPIUrls.uiState.get(), result);
        successMessage && notify('success', successMessage);
      })
      .catch(err => {
        logError('Failed to save user preferences', err);
        errorMessage && notify('error', errorMessage);
      });
  }

  return {
    uiState,
    saveUiState,
  };
}

export function useCloseableMessage(key: string) {
  const { uiState: uiStateResource, saveUiState } = useUiState();
  const uiState = getOrUndefined(uiStateResource);
  const isClosed = uiState?.closedMessages?.[key];

  const closeMessage = () => saveUiState({ closedMessages: { [key]: true } });
  const toggleMessage = () => saveUiState({ closedMessages: { [key]: isClosed ? false : true } });

  return {
    isClosed,
    closeMessage,
    toggleMessage,
    isLoading: uiStateResource.state === 'fetching',
  };
}
