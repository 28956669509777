import config from 'config';
import useSessionStorage from 'hooks/useSessionStorage';
import { ICompany } from 'types/company';
import { getOrUndefined } from 'util/resource';
import useResource from 'util/resource/useResource';
import ApiBase from '../ApiBase';
import { FundingStage, Industry, Stage } from 'types';
import { TinyOrganizationDTO } from 'types/organization';

const api = ApiBase();
const baseUrl = config.COMPANIES_API_URL;

export const companyOnboardingSteps = [
  'CompanyInfo',
  'IndustriesAndStage',
  'CompanyMission',
  'Kpis',
  'CompanyBranding',
  'ProblemSolutionUnfairAdvantage',
  'PitchDeck',
  'InviteYourStakeholders',
  'PostFirstUpdate',
  'IndustryAndBusinessFocus',
  'BasicProfile',
  'ProductAndFundingStage',
  'UserProfile',
] as const;

export type CompanyOnboardingStep = (typeof companyOnboardingSteps)[number];

export type OrganizationOnboardingStep =
  | 'OrganizationType'
  | 'OrganizationIndustries'
  | 'Services'
  | 'OrganizationProfile'
  | 'InviteOrganizationMembers';

export type CommunityOnboardingStep =
  | 'CommunityBranding'
  | 'CommunityDescription'
  | 'InviteYourTeam'
  | 'InviteCompanies'
  | 'InviteInvestorsAndMentors';

export type InvestorOnboardingStep =
  | 'ChooseYourRole'
  | 'InvestmentPreferences'
  | 'InvestorType'
  | 'InvestmentRange'
  | 'Interests'
  | 'BoardPosition';

export type CommonOnboardingStep = 'UserProfile';

export type OnboardingStatus = 'InProgress' | 'Completed';

export type OnboardingStep =
  | CommonOnboardingStep
  | InvestorOnboardingStep
  | CompanyOnboardingStep
  | CommunityOnboardingStep
  | OrganizationOnboardingStep;

export interface Step {
  step: OnboardingStep;
  isCompleted: boolean;
  completenessPercentage: number;
}

export type OnboardingType =
  | { type: 'User' }
  | { type: 'Company'; company: ICompany }
  | { type: 'Community'; communityId: number }
  | { type: 'Organization'; organizationId: UUID };

export type Onboarding = {
  checklist: OnboardingPart;
  wizard: OnboardingPart;
} & OnboardingType;

export interface OnboardingPart {
  status: OnboardingStatus;
  currentStep?: OnboardingStep;
  steps: Step[];
}

export interface OnboardingWizardStatus {
  company?: ICompany;
  organization?: TinyOrganizationDTO;
  status: OnboardingStatus;
}

export function useCompanyChecklistSessionStorage(companySlug: string) {
  const [value, setValue] = useSessionStorage<{
    completedSteps: string[];
  }>(`company-checklist-${companySlug}`, { completedSteps: [] });

  function markStepAsCompleted(step: OnboardingStep) {
    setValue({
      completedSteps: [...value.completedSteps, step],
    });
  }

  return { completedStepsSessionStorage: value, markStepAsCompleted };
}

// Because some of the steps in the company onboarding are async, we need to store the completed steps in session storage
// to make the checklist component update correctly for the user.
// The async events are events from documents/content/reporting that are reported to companies backend through pub-sub,
// and is used to calculate the onboarding status.
export function markStepsAsCompleted(onboardingSteps: Step[], completedSteps: string[]) {
  return onboardingSteps.map(it =>
    completedSteps.includes(it.step) ? { ...it, isCompleted: true, completenessPercentage: 100 } : it,
  );
}

export const onboardingApiUrls = {
  getStatus: `${baseUrl}/onboarding/status`,
  getUserOnboarding: `${baseUrl}/onboarding/user`,
  getCompanyOnboarding: (companySlug: string) => `${baseUrl}/onboarding/companies/${companySlug}`,
  getOrganizationOnboarding: (organizationSlug: string) => `${baseUrl}/onboarding/organizations/${organizationSlug}`,
  getCommunityChecklist: (communitySlug: string) => `${baseUrl}/communities/${communitySlug}/checklist`,
};

export const OnboardingAPI = {
  patchUserOnboarding: (status: 'Skipped' | 'Completed') => api.patch(onboardingApiUrls.getUserOnboarding, { status }),
  patchCompanyOnboarding: (companySlug: string, status: 'Skipped' | 'Completed') =>
    api.patch(onboardingApiUrls.getCompanyOnboarding(companySlug), { status }),
  patchOrganizationOnboarding: (organizationSlug: string, status: 'Skipped' | 'Completed') =>
    api.patch(onboardingApiUrls.getOrganizationOnboarding(organizationSlug), { status }),

  saveOnboardingPDF: (companySlug: string, body: FormData) =>
    api.postMultiform<Onboarding>(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/data`, body),
  suggestions: {
    generateBasicProfile: (companySlug: string) =>
      api.post<{
        name?: string;
        employees?: number;
        founded?: string;
        website?: string;
        location?: string;
      }>(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/basic-profile`, {}),
    generateMission: (companySlug: string) =>
      api.post<string>(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/mission`, {}),
    generateExecutiveSummary: (companySlug: string) =>
      api.post(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/executive-summary`, {}),
    generateTeamDescription: (companySlug: string) =>
      api.post(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/team`, {}),
    generateIndustries: (companySlug: string) =>
      api.post<Industry[]>(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/industries`, {}),
    generateImpactGoals: (companySlug: string) =>
      api.post(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/impact-goals`, {}),
    generateStages: (companySlug: string) =>
      api.post<{
        fundingStage?: Stage;
        productStage?: FundingStage;
      }>(`${baseUrl}/onboarding/companies/${companySlug}/suggestions/stages`, {}),
  },
};

export const companyOnboardingKey = (companySlug: string) => onboardingApiUrls.getCompanyOnboarding(companySlug);

export function useCompanyOnboarding(companySlug: string) {
  const { completedStepsSessionStorage } = useCompanyChecklistSessionStorage(companySlug);

  const result = useResource<Onboarding>(companyOnboardingKey(companySlug));
  const onboarding = getOrUndefined(result.resource);

  const checklistSteps = markStepsAsCompleted(
    onboarding?.checklist.steps || [],
    completedStepsSessionStorage.completedSteps,
  );
  return { ...result, checklistSteps };
}
