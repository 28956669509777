import { CommonOnboardingStep, InvestorOnboardingStep, OnboardingPart, onboardingApiUrls } from 'apis/OnboardingAPI';
import React, { useEffect, useState } from 'react';
import { SelfUserProfile } from 'types/user';
import Wizard, { OnboardingStage } from 'ui/modules/wizards/Wizard';
import { track } from 'util/analytics';
import UserInfoForm from '../user/UserInfo/UserInfoForm';
import InvestorPreferenceForm from './InvestorPreferences/InvestorPreferenceForm';
import InvestorTypeForm from './InvestorType/InvestorTypeForm';
import InvestmentRangeForm from './InvestorPreferences/InvestmentRangeForm';
import { useIndustries } from 'apis/CompanyAPI/labels/useIndustries';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { invalidate } from 'hooks/useSWR';
import { featuredMatchesKey } from 'apis/CompanyAPI/users/useFeaturedMatches';
import ChooseYourRole from './ChooseYourRoleForm';
import { CommunityInviteDetails } from 'types/company/community';
import { WizardType } from 'pages/Dashboard/Dashboard';
import InterestsForm from './InterestForm';
import BoardPosition from './BoardPosition';

export const USER_WIZARD_CONTENT_MIN_HEIGHT = '225px';

export default function UserOnboardingWizard({
  invite,
  user,
  onboarding,
  onClose,
  onWizardCompleted,
  onShowWizard,
}: {
  user: SelfUserProfile;
  onboarding: OnboardingPart;
  onClose: () => void;
  onWizardCompleted: () => void;
  onShowWizard: (wizard: WizardType) => void;
  invite?: CommunityInviteDetails;
}) {
  useEffect(() => {
    track('investor-onboarding-started');
  }, []);

  const completedStages = onboarding.steps.filter(step => step.isCompleted).map(step => step.step);

  const handleClose = () => {
    onClose();
    invalidate(userDashboardKey);
    invalidate(onboardingApiUrls.getUserOnboarding);
    track('investor-onboarding-completed');
  };

  // Preload industries
  useIndustries();

  const canChooseRole = !invite || invite.invitedAs === 'NotSpecified';

  const stages: OnboardingStage<InvestorOnboardingStep | CommonOnboardingStep>[] = [
    {
      name: 'ChooseYourRole',
      title: canChooseRole ? 'Choose your role to get started' : 'Welcome',
      hideStageInformation: true,
      content: ({ onComplete }) => (
        <ChooseYourRole
          invite={invite}
          userProfile={user}
          onShowWizard={onShowWizard}
          onStart={() => track('investor-onboarding-role-step-started')}
          onComplete={() => {
            invalidate(featuredMatchesKey);
            onComplete();
            track('investor-onboarding-role-step-completed');
          }}
        />
      ),
    },
    {
      name: 'InvestorType',
      title: 'What kind of investor do you identify as?',
      content: ({ onComplete }) => (
        <InvestorTypeForm
          userProfile={user}
          onStart={() => track('investor-onboarding-type-step-started')}
          closeWizard={handleClose}
          onComplete={() => {
            invalidate(featuredMatchesKey);
            onComplete();
            track('investor-onboarding-type-step-completed');
          }}
        />
      ),
    },
    {
      name: 'InvestmentPreferences',
      title: 'What is your industry and business focus?',
      subtitle: 'Add as many areas as you want, choose at least one category',
      content: ({ onComplete, onBack }) => (
        <InvestorPreferenceForm
          userProfile={user}
          onStart={() => track('investor-onboarding-investmentpreferences-step-started')}
          closeWizard={handleClose}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-investmentpreferences-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'InvestmentRange',
      title: 'Add your preferred range & capacity',
      content: ({ onComplete, onBack }) => (
        <InvestmentRangeForm
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-investmentpreferences-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-investmentrange-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'Interests',
      title: 'What are your interests?',
      subtitle: 'Add as many areas as you want, choose at least one category',
      content: ({ onComplete, onBack }) => (
        <InterestsForm
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-interests-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-interests-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
    {
      name: 'BoardPosition',
      title: 'Board Position',
      subtitle: 'Are you open for requests regarding board positions?',
      content: ({ onComplete, onBack }) => (
        <BoardPosition
          userProfile={user}
          closeWizard={handleClose}
          onStart={() => track('investor-onboarding-board-position-step-started')}
          onComplete={() => {
            onComplete();
            track('investor-onboarding-board-position-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },

    {
      name: 'UserProfile',
      title: 'Preview your profile card and add the missing information',
      content: ({ onComplete, onBack }) => (
        <UserInfoForm
          userProfile={user}
          context="onboarding"
          onStart={() => track('investor-onboarding-userprofile-step-started')}
          onComplete={() => {
            onComplete();
            onWizardCompleted();
            track('investor-onboarding-userprofile-step-completed');
          }}
          onBack={onBack}
        />
      ),
    },
  ];

  // The first step in the user/investor onboarding is choosing your role
  // however, if we have role information trhough invitations, we can skip this step
  // but then the wizard starts at step 2, which is confusing. So we need to filter out the first step if it's completed
  // the first time the wizard loads. That's why I store the original onboarding in state
  const [originalOnboardingState] = useState(onboarding);

  const filteredStages = stages
    .filter(stage => onboarding.steps.find(step => step.step === stage.name))
    .filter(stage =>
      originalOnboardingState.currentStep === 'ChooseYourRole' ? true : stage.name !== 'ChooseYourRole',
    );

  const filteredCompletedStages = completedStages.filter(stage =>
    originalOnboardingState.currentStep === 'ChooseYourRole' ? true : stage !== 'ChooseYourRole',
  );

  return <Wizard completedStages={filteredCompletedStages} stages={filteredStages} />;
}
