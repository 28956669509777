import styled from '@emotion/styled';
import React, { useState, useCallback } from 'react';
import { Thumbnail } from 'react-pdf';
import { bluePlanetTheme } from 'ui/theme';
import styles from './styles.scss';

interface Props {
  inView: boolean;
  index: number;
  onThumbnailClick: (page?: number) => void;
}

const ThumbnailWrapper = styled.div(
  ({ isActive }: { isActive: boolean }) => `
  height: 100%;

  & canvas {
    width: 100% !important;
  }

  & > a {
    display: block;
  }

  ${isActive ? `border: 2px solid ${bluePlanetTheme.bluePlanetPalette.indigo.light};` : ''}
  & * {
    height: 100% !important;
  }
`,
);

export default function LazyLoadSlide(props: Props) {
  const { index, inView, onThumbnailClick } = props;
  const [hasLoaded, setHasLoaded] = useState(false);

  const setLoaded = useCallback(() => {
    if (inView) setHasLoaded(true);
  }, [inView, setHasLoaded]);

  return (
    <div className={styles.emblaSlide}>
      <div className={'embla__lazy-load'.concat(hasLoaded ? ' embla__lazy-load--has-loaded' : '')}>
        {!hasLoaded && <span className="embla__lazy-load__spinner" />}
        {inView && (
          <ThumbnailWrapper onLoad={setLoaded} isActive={false} key={`page_${index + 1}`}>
            <Thumbnail pageNumber={index + 1} onItemClick={({ pageNumber }) => onThumbnailClick(pageNumber)} />
          </ThumbnailWrapper>
        )}
      </div>
    </div>
  );
}
